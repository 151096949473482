import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Chart } from './components/Chart';
import Divider from '@material-ui/core/Divider';
import {
  STRING_EC2_AGS,
  STRING_EC2_1ST_CONVERTER_A,
  STRING_EC2_1ST_CONVERTER_B,
  STRING_EC2_2ND_CONVERTER_A,
  STRING_EC2_2ND_CONVERTER_B,
  STRING_EC2_2ND_CONVERTER_C,
  STRING_EC2_2ND_CONVERTER_A_1,
  STRING_EC2_PROXY,
  STRING_EC2_SERVICETOOLKIT,
  STRING_EC2_PRIVACY,
  STRING_RDS
} from '../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const Server = ({ match, history }) => {
  const classes = useStyles();
  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== "true")
      history.push('/')
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"1st Converter CPU/Memory"}
            </Typography>
            <div className={classes.content}>
              <Chart
                converterNames={[
                  STRING_EC2_1ST_CONVERTER_A,
                  STRING_EC2_1ST_CONVERTER_B
                ]}
                metricName="CPUUtilization"
                color={"#fff9db"}
                button={true}
                width={"50%"}
                height={"100%"}
              />
              <Chart
                converterNames={[
                  STRING_EC2_1ST_CONVERTER_A,
                  STRING_EC2_1ST_CONVERTER_B
                ]}
                metricName="MemoryUtilization"
                color={"#fff9db"}
                button={true}
                width={"50%"}
                height={"100%"}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"2nd Converter CPU/Memory"}
            </Typography>
            <div className={classes.content}>
              <Chart
                converterNames={[
                  STRING_EC2_2ND_CONVERTER_A,
                  STRING_EC2_2ND_CONVERTER_B,
                  STRING_EC2_2ND_CONVERTER_C,
                  STRING_EC2_2ND_CONVERTER_A_1
                ]}
                metricName="CPUUtilization"
                color={"#fff9db"}
                button={true}
                width={"50%"}
                height={"100%"}
              />
              <Chart
                converterNames={[
                  STRING_EC2_2ND_CONVERTER_A,
                  STRING_EC2_2ND_CONVERTER_B,
                  STRING_EC2_2ND_CONVERTER_C,
                  STRING_EC2_2ND_CONVERTER_A_1
                ]}
                metricName="MemoryUtilization"
                color={"#fff9db"}
                button={true}
                width={"50%"}
                height={"100%"}
              />
            </div>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"Proxy Server CPU/Memory"}
            </Typography>
            <div className={classes.content}>
              <Chart ec2Name={STRING_EC2_PROXY}
                metricName="CPUUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />
              <Chart ec2Name={STRING_EC2_PROXY}
                metricName="MemoryUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"Privacy Server CPU/Memory"}
            </Typography>
            <div className={classes.content}>
              <Chart ec2Name={STRING_EC2_PRIVACY}
                metricName="CPUUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />
              <Chart ec2Name={STRING_EC2_PRIVACY}
                metricName="MemoryUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />

            </div>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"Servicetoolkit Server CPU/Memory"}
            </Typography>
            <div className={classes.content}>
              <Chart ec2Name={STRING_EC2_SERVICETOOLKIT}
                metricName="CPUUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />
              <Chart ec2Name={STRING_EC2_SERVICETOOLKIT}
                metricName="MemoryUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"AGS Server CPU/Memory"}
            </Typography>
            <div className={classes.content}>
              <Chart ec2Name={STRING_EC2_AGS}
                metricName="CPUUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />
              <Chart ec2Name={STRING_EC2_AGS}
                metricName="MemoryUtilization"
                color={"#fce6fc"}
                button={false}
                width={"50%"}
                height={"100%"} />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"DB Server CPU/Queries"}
            </Typography>
            <div className={classes.content}>
              <Chart rdsName={STRING_RDS}
                metricName="CPUUtilization"
                unit="Percent"
                color={"#dbf0ff"}
                button={false}
                width={"50%"}
                height={"100%"} />
              <Chart rdsName={STRING_RDS}
                metricName="Queries"
                unit="Count/Second"
                color={"#dbf0ff"}
                button={false}
                width={"50%"}
                height={"100%"} />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <Grid>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h3"
            >
              {"DB Connections"}
            </Typography>
            <div className={classes.content}>
              <Chart rdsName={STRING_RDS}
                metricName="DatabaseConnections"
                unit="Count"
                color={"#dbf0ff"}
                button={false}
                width={"100%"}
                height={"100%"} />
            </div>
          </Grid>
        </Grid>


      </Grid>
    </div>
  );
};

export default Server;
