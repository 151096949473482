import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Card, Backdrop, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment';
import { getServiceToolkitInfo } from '../../../APIs/tokapis';
import useAsync from '../../../../../asyncNet';
import BSEngineerDialog from './BSEngineerDialog';
import secureLocalStarage from 'react-secure-storage';
import DeleteFirmwareDialog from './DeleteFirmwareDialog';
import { deploy } from "../../../../../config";
import {
  STRING_FW_DETAIL_INFO,
  STRING_DOWNROAD_URL,
  STRING_FW_REGISTER_AT,
  STRING_FW_LAST_MODIFY_AT,
  STRING_ENTIRE_CRC,
  STRING_FOTA_FW,
  STRING_SERVICE_TOOLKIT_FW,
  STRING_BS_FW,
  STRING_ACTIVE,
  STRING_FORCE,
  STRING_GROUP,
  STRING_FIRMWARE_DELETE,
  STRING_FW_DESCRIPTION,
  STRING_FILE_NAME,
  STRING_FILE_SIZE,
  STRING_FILE_CRC,
  STRING_DATA_CRC,
  STRING_DATE_FORMAT_INCLUDE_SEC,
  STRING_FW_VERSION,
  STRING_SW_CODE,
  STRING_UPLOAD_TIME,
  STRING_MINUTE,
  STRING_CLOSE,
  STRING_SETTING_ENGINEER,
  STRING_BS_CHECK,
  STRING_DELETE_AFTER_DISABLE_ACTIVE_FLAG
} from '../../../StringTable/StringTable';
import { ROLE_SMARTPLUS_ENGINEER_MANAGER, ROLE_SMARTPLUS_FW_MANAGER, ROLE_SMARTPLUS_SUPER_ADMIN } from 'common/role';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  datas: {
    marginLeft: theme.spacing(2)
  },
  icons: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2)
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const DetailFirmwareDialog = props => {
  const { open, handleClose, refetch, selectedFW, firmwareType } = props;
  const [state, tryRefetch] = useAsync(() => getServiceToolkitInfo(selectedFW.version, selectedFW.swCode), [], false);
  const { loading, data: stInfo } = state;
  const [engineerOpen, setEngineerOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const userRoles = secureLocalStarage.getItem('account').userRoles;
  const classes = useStyles();
  const handleCloseClick = () => handleClose();
  const setOpenClick = () => setEngineerOpen(true);
  const setCloseClick = () => setEngineerOpen(false);
  const setDeleteClick = () => setDeleteOpen(true);
  const setDeleteCloseClick = () => setDeleteOpen(false);
  const refetchList = () => {
    refetch();
  };

  useEffect(() => {
    if (selectedFW !== null) {
      tryRefetch();
    }
  }, [selectedFW]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_FW_DETAIL_INFO}</DialogTitle>
        <DialogContent>
          {/* <Divider className={classes.divider} /> */}
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.description != "" ? selectedFW.description : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_REGISTER_AT}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.fwRegisterAt ? moment((selectedFW.fwRegisterAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_LAST_MODIFY_AT}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.lastModifyAt ? moment((selectedFW.lastModifyAt - 9 * 60 * 60) * 1000).format(STRING_DATE_FORMAT_INCLUDE_SEC) : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.title}>
            <Card className={classes.card} style={{ width: 150, height: 30 }}>
              {firmwareType === "firmwareOfficial" && <Typography variant="h5" color="primary">{STRING_FOTA_FW}</Typography>}
              {firmwareType === "firmwareBS" && <Typography variant="h5" color="primary">{STRING_BS_FW}</Typography>}
            </Card>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FILE_NAME}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.fileName ? selectedFW.fileName : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FILE_SIZE}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.fileSize ? selectedFW.fileSize : '-'} bytes</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FILE_CRC}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.fileCrc ? selectedFW.fileCrc : '-'}</Typography>
            <Typography className={classes.datas} variant="caption" color="secondary">{STRING_ENTIRE_CRC}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_DATA_CRC}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.dataCrc ? selectedFW.dataCrc : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_VERSION}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.version ? 'V' + parseInt(selectedFW.version.substr(0, 2), 16) + '-' + parseInt(selectedFW.version.substr(2, 2), 16) : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_SW_CODE}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.swCode ? '0x' + selectedFW.swCode : '-'}</Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_DOWNROAD_URL}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.downloadUrl ? selectedFW.downloadUrl : '-'}</Typography>
          </div>
          {firmwareType === "firmwareOfficial" &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_ACTIVE}</Typography>
                <Checkbox className={classes.icons}
                  checked={selectedFW.activeFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled
                />
                <div>　　　</div>
                <Typography variant="h6">{STRING_FORCE}</Typography>
                <Checkbox className={classes.icons}
                  checked={selectedFW.forceFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled
                />
                <div>　　　</div>
                <Typography variant="h6">{STRING_GROUP}</Typography>
                <Checkbox className={classes.icons}
                  checked={selectedFW.groupFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled
                />
                <div>　　　</div>
                <Typography variant="h6">{STRING_BS_CHECK}</Typography>
                <Checkbox className={classes.icons}
                  checked={selectedFW.bsCheckFlag}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled
                />
              </div>
            </div>
          }
          <Divider className={classes.divider} />
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_UPLOAD_TIME}{' :'}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.updateTime != "" ? selectedFW.updateTime : '-'} {STRING_MINUTE}</Typography>
          </div>
          {/*OS*/}
          {firmwareType === "firmwareOfficial" && stInfo !== null && stInfo.stInfo !== undefined &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.title}>
                <Card className={classes.card} style={{ width: 200, height: 30 }}>
                  <Typography variant="h5" color="primary">{STRING_SERVICE_TOOLKIT_FW}</Typography>
                </Card>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FILE_NAME}{' :'}</Typography>
                <Typography className={classes.datas} variant="h6">{stInfo.stInfo.fileName ? stInfo.stInfo.fileName : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FILE_SIZE}{' :'}</Typography>
                <Typography className={classes.datas} variant="h6">{stInfo.stInfo.fileSize ? stInfo.stInfo.fileSize : '-'} bytes</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FILE_CRC}{' :'}</Typography>
                <Typography className={classes.datas} variant="h6">{stInfo.stInfo.fileCrc ? stInfo.stInfo.fileCrc : '-'} </Typography>
                <Typography className={classes.datas} variant="caption" color="secondary">{STRING_ENTIRE_CRC}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_DATA_CRC}{' :'}</Typography>
                <Typography className={classes.datas} variant="h6">{stInfo.stInfo.dataCrc ? stInfo.stInfo.dataCrc : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_FW_VERSION}{' :'}</Typography>
                <Typography className={classes.datas} variant="h6">{stInfo.stInfo.version ? 'V' + parseInt(stInfo.stInfo.version.substr(0, 2), 16) + '-' + parseInt(stInfo.stInfo.version.substr(2, 2), 16) : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_SW_CODE}{' :'}</Typography>
                <Typography className={classes.datas} variant="h6">{stInfo.stInfo.swCode ? '0x' + stInfo.stInfo.swCode : '-'}</Typography>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Typography variant="h6">{STRING_DOWNROAD_URL}{' :'}</Typography>
                <Typography className={classes.datas} variant="h6">{stInfo.stInfo.downloadUrl ? stInfo.stInfo.downloadUrl : '-'}</Typography>
              </div>
            </div>
          }
          {firmwareType === "firmwareBS" &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Button onClick={setOpenClick} variant="contained" color="primary" disabled={!(userRoles.includes(ROLE_SMARTPLUS_ENGINEER_MANAGER) || userRoles.includes(ROLE_SMARTPLUS_FW_MANAGER) || userRoles.includes(ROLE_SMARTPLUS_SUPER_ADMIN))}>
                  {STRING_SETTING_ENGINEER}
                  <GroupAddIcon className={classes.linear} />
                </Button>

              </div>
            </div>
          }
          {
            userRoles.includes(ROLE_SMARTPLUS_SUPER_ADMIN) === true && selectedFW.releaseType !== 'OS' && deploy !== 'prod' &&
            <div>
              <Divider className={classes.divider} />
              <div className={classes.linear}>
                <Button onClick={setDeleteClick} variant="contained" color="secondary"
                  disabled={selectedFW.activeFlag === true}>
                  {STRING_FIRMWARE_DELETE}
                  <DeleteForeverIcon className={classes.linear} />
                </Button>
                {selectedFW.activeFlag === true &&
                  <Typography className={classes.datas} variant="caption" >{STRING_DELETE_AFTER_DISABLE_ACTIVE_FLAG}</Typography>
                }
              </div>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
        </DialogActions>
      </Dialog>
      {engineerOpen === true && firmwareType === "firmwareBS" &&
        <BSEngineerDialog open={engineerOpen} handleClose={setCloseClick} selectedFW={selectedFW} refetch={tryRefetch} />
      }
      {deleteOpen === true && deploy !== 'prod' &&
        <DeleteFirmwareDialog open={deleteOpen} handleClose={setDeleteCloseClick} selectedFW={selectedFW} refetch={refetchList} detailClose={handleCloseClick} />
      }
    </div>
  );
};

DetailFirmwareDialog.propTypes = {
  className: PropTypes.string
};

export default DetailFirmwareDialog;
