import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { setEcsLogLevel } from '../../APIs/mateApis';
import BlackListAddPopup from './BlackListAddPopup';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));

const BlackListToolbar = ({
  syncBlackList,
  addBlackList,
  open,
  handleAddOpen,
  handleAddClose
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title={'컨버터'} subheader={'블랙리스트 수정 및 목록 확인'} />
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" variant="contained" onClick={handleAddOpen}>
          {'블랙리스트 추가'}
        </Button>
        <Button color="primary" variant="contained" onClick={syncBlackList}>
          {'블랙리스트 싱크'}
        </Button>
      </CardActions>
      <BlackListAddPopup
        open={open}
        handleClose={handleAddClose}
        addBlackList={addBlackList}
      />
    </Card>
  );
};

export default BlackListToolbar;
