import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {
  NO_PERMISSION,
  READ_PERMISSION,
  WRITE_PERMISSION
} from '../../../../../../../views/Common/Config/naviwatchConfig';
import { dispatchContext } from '../../../../../../../App';

import { useRecoilValue } from 'recoil';
import { userAccount } from '../../../../../../../common/mainStore';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(3)
  }
}));
const PAGE_UPDATE_STATE_INIT = 0;
const PAGE_UPDATE_STATE_RUNNING = 1;
const PAGE_UPDATE_STATE_FINISH = 2;

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const getProductPolicies = (fullPolicies, productName) => {
  console.log('called getProductPolicies');
  var productPolicies = fullPolicies.filter(
    policy =>
      policy.productName
        .toLocaleLowerCase()
        .includes(productName.toLowerCase()) === true
  );
  return productPolicies.length > 0 ? productPolicies[0].policy : undefined;
};

const getMyPolicies = (userInfo, fullRoles, productName) => {
  var myRole = userInfo.userRoles;
  var myPolicy = undefined;
  console.log('called getMyPolicies');
  for (var i = 0; i < myRole.length; ++i) {
    var role = fullRoles.filter(role => role.roleId === myRole[i]);
    if (
      role.length > 0 &&
      role[0].roleName.toLowerCase().includes(productName.toLowerCase()) ===
        true
    ) {
      myPolicy = role[0].policy;
      break;
    }
  }
  return myPolicy;
};

const setSidebarPages = (
  fullPolicies,
  myPolicies,
  sidebar,
  setSidebar,
  pageUpdating
) => {
  if (sidebar === undefined || setSidebar === undefined) {
    return;
  }

  if (myPolicies !== undefined) {
    for (var pageIdx = 0; pageIdx < sidebar.length; ++pageIdx) {
      var page = sidebar[pageIdx];
      for (var policyIdx = 0; policyIdx < myPolicies.length; ++policyIdx) {
        var policy = fullPolicies.filter(
          policy => policy.policyId === myPolicies[policyIdx]
        );
        if (
          policy.length > 0 &&
          policy[0].policyName
            .toLowerCase()
            .includes(page.name.toLocaleString()) === true
        ) {
          if (
            policy[0].policy === READ_PERMISSION ||
            policy[0].policy === WRITE_PERMISSION
          ) {
            page.permission = policy[0].policy;
            setSidebar(
              sidebar.map(side =>
                side.name.toLowerCase().includes(page.name.toLocaleString()) ===
                true
                  ? { ...side, permission: page.permission }
                  : side
              )
            );
            break;
          }
        }
      }
    }
  }
  pageUpdating(PAGE_UPDATE_STATE_FINISH);
};

const SidebarNav = props => {
  const {
    pages,
    className,
    userInfo,
    subNaviState,
    setSubNaviState,
    naviIcons,
    ...rest
  } = props;

  const classes = useStyles();
  const [account, setAccount] = useState(undefined);
  const [productFullPolicies, setProductFullPolicies] = useState(undefined);
  const [myPolicies, setMyPolicies] = useState(undefined);
  const [currentPages, setCurrentPages] = useState(pages);
  const [pageUpdating, setPageUpdating] = useState(PAGE_UPDATE_STATE_INIT);
  const userAccountState = useRecoilValue(userAccount);
  const dispatch = useContext(dispatchContext);

  const handleClick = subNaviIndex => {
    if (subNaviIndex === undefined) {
      setSubNaviState(
        subNaviState.map(item => ({ ...item, openState: false }))
      );
    } else {
      setSubNaviState(
        subNaviState.map(item =>
          item.index === subNaviIndex
            ? { ...item, openState: !item.openState }
            : item
        )
      );
    }
  };

  useEffect(() => {
    if (pageUpdating === PAGE_UPDATE_STATE_FINISH) {
      console.log('useEffect pages setting');
      dispatch({
        type: 'PAGE_UPDATE',
        pages: currentPages
      });
    }
  }, [pageUpdating]);

  useEffect(() => {
    console.log('useEffect policies setting');
    setSidebarPages(
      productFullPolicies,
      myPolicies,
      currentPages,
      setCurrentPages,
      setPageUpdating
    );
  }, [productFullPolicies, myPolicies]);

  useEffect(() => {
    if (account !== undefined) {
      console.log('setting role and policy after setting of account');
      var fullRoles = sessionStorage.getItem('fullRoles');
      var fullPolicies = sessionStorage.getItem('fullPolicies');
      if (fullRoles !== 'undefined' && fullPolicies !== 'undefined') {
        var fullRolesObj = JSON.parse(fullRoles);
        var fullPoliciesObj = JSON.parse(fullPolicies);
        setProductFullPolicies(
          getProductPolicies(fullPoliciesObj, localStorage.productName)
        );
        setMyPolicies(
          getMyPolicies(account, fullRolesObj, localStorage.productName)
        );
      }
    }
  }, [account]);

  useEffect(() => {
    console.log('Initial loading of sidebarNav');
    setPageUpdating(PAGE_UPDATE_STATE_RUNNING);
    console.log('account: ' + userAccountState);
    var userInfoObj = JSON.parse(userAccountState);
    setAccount(userInfoObj);
  }, []);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {currentPages.map(page => (
        <div>
          {account !== undefined && page.permission >= READ_PERMISSION && (
            <div>
              <ListItem
                className={classes.item}
                disableGutters
                key={page.title}
                onClick={() => handleClick(page.subNavIndex)}>
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}>
                  <div className={classes.icon}>
                    {naviIcons.filter(icon => icon.name === page.name)[0].icon}
                  </div>
                  {page.title}
                </Button>
              </ListItem>
              {page.subNavIndex !== undefined && (
                <Collapse
                  in={subNaviState[page.subNavIndex].openState}
                  timeout="auto"
                  unmountOnExit>
                  <List component="div" disablePadding>
                    {subNaviState[page.subNavIndex].subNav.map(subItem => (
                      <ListItem
                        className={classes.item}
                        disableGutters
                        key={subItem.title}>
                        <Button
                          activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={subItem.href}>
                          <div className={classes.nested} />
                          <div className={classes.icon}>{subItem.icon}</div>
                          {subItem.title}
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          )}
        </div>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
