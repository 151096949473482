import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  makeStyles,
  IconButton,
  Tooltip,
  TextField,
  DialogTitle
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const BlackListAddPopup = ({ open, handleClose, addBlackList }) => {
  const [blackList, setBlackList] = useState([]);
  const handleAddBlacList = () => {
    setBlackList([...blackList, '']);
  };

  const handleChangeAddDeviceId = (event, index) => {
    let currentblackList = [...blackList];
    currentblackList[index] = event.target.value;
    setBlackList(currentblackList);
  };

  const handleChangeDeleteDeviceId = index => {
    let currentblackList = [...blackList];
    currentblackList.splice(index, 1);
    setBlackList(currentblackList);
  };

  const isValid = useMemo(() => {
    let result = false;
    blackList.map(item => {
      if (item.length == 16) {
        result = true;
      } else {
        result = false;
      }
    });
    return result;
  }, [blackList]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>블랙리스트 추가</DialogTitle>
        <DialogContent>
          <Button onClick={handleAddBlacList} color="primary" autoFocus>
            <span>디바이스 아이디 추가</span>
          </Button>
          {blackList &&
            blackList.length > 0 &&
            blackList.map((item, index) => (
              <div fullWidth={true} key={index}>
                <TextField
                  label="deviceId"
                  margin="dense"
                  name="deviceId"
                  style={{ width: '80%' }}
                  onChange={event => handleChangeAddDeviceId(event, index)}
                  required
                  value={blackList[index]}
                  variant="outlined"
                />
                <Tooltip
                  title={`삭제`}
                  onClick={() => handleChangeDeleteDeviceId(index)}>
                  <IconButton aria-label={'아이디 삭제'}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!isValid}
            onClick={() => addBlackList(blackList)}
            color="primary">
            추가
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BlackListAddPopup;
