import axios from 'axios';

import { deploy } from 'config';
var ApiInstance = null;
var ECSClusterName = '';
var ECSServiceName = '';
var DBClusterName = '';
var LoadBalancerName = '';
var OTA_DEVICE_FW_PATH = '/mate/firmwares/';
var OTA_DEVICE_CERT_PATH = '/mate/certificates/';
var OTA_CERTIFICATE_ARN = '';
var OTA_S3_BUCKET = '';
var OTA_S3_SIGNING_PREFIX = 'SignedImages/';

if (deploy === 'dev') {
  ApiInstance = axios.create({
    baseURL: 'https://krnm-dev-nw-api.naviensmartcontrol.com'
  });
  ECSClusterName = 'mate-dev-ecs-cluster-converter';
  ECSServiceName = 'mate-dev-ecs-service-converter';
  DBClusterName = 'mate-dev-stack-rds-dbcluster624cfb91-15qgxw7spc9rk';
  LoadBalancerName = 'net/mate-dev-ec2-nlb-converter/b0349d8235396261';
  OTA_CERTIFICATE_ARN =
    'arn:aws:acm:ap-northeast-2:195830161280:certificate/db88e944-5279-4c26-aaf4-7e9cf6634123';
  OTA_S3_BUCKET = 'mate-dev-s3-bucket-firmware';
} else if (deploy === 'stage') {
  ApiInstance = axios.create({
    baseURL: 'https://krnm-stg-nw-api.naviensmartcontrol.com'
  });
  ECSClusterName = 'mate-stage-ecs-cluster-converter';
  ECSServiceName = 'mate-stage-ecs-service-converter';
  DBClusterName = 'mate-stage-stack-rds-dbcluster624cfb91-p6h1h9u2quer';
  LoadBalancerName = 'net/mate-stage-ec2-nlb-converter/ab54961126aa0377';
  OTA_CERTIFICATE_ARN =
    'arn:aws:acm:ap-northeast-2:268926719761:certificate/181e3d5b-2b9a-4260-b746-f61dbbf737fa';
  OTA_S3_BUCKET = 'mate-stage-s3-bucket-firmware';
} else if (deploy === 'prod') {
  ApiInstance = axios.create({
    baseURL: 'https://krnm-nw-api.naviensmartcontrol.com'
  });
  ECSClusterName = 'mate-prod-ecs-cluster-converter';
  ECSServiceName = 'mate-prod-ecs-service-converter';
  DBClusterName = 'mate-prod-stack-rds-dbcluster624cfb91-1mnlpj07a419k';
  LoadBalancerName = 'net/mate-prod-ec2-nlb-converter/4b73c1f49eb83f03';
  OTA_CERTIFICATE_ARN =
    'arn:aws:acm:ap-northeast-2:671769328762:certificate/983ea55e-db00-426b-957c-a2ab58a226ad';
  OTA_S3_BUCKET = 'mate-prod-s3-bucket-firmware';
}

export async function dashboardGetDeviceArea() {
  var response = undefined;
  let reqUrl = '/naviwatch/api/v1/monitoring/device/get-region-count';
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log(response.data);
  return response.data;
}
export async function dashboardGetModelCount(query) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-model-count?query=${query}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetMobileActiveCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/mobile/get-active-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetUserTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-active-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetDeviceActiveCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-active-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetServerMetric(metricName, currentTime) {
  var response = undefined;
  let reqUrl =
    `naviwatch/api/v1/monitoring/server/get-cluster-metric?clusterName=${ECSClusterName}` +
    `&serviceName=${ECSServiceName}&metricName=${metricName}&endtimeSec=${currentTime}` +
    `&timelineSec=1200&timePeriodSec=60`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetDeviceWeeklyChart(day) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-weekly-count?day=${day}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function dashboardGetUserCount() {
  var response = undefined;
  let reqUrl = `/`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dashboardGetUserIncrease(day, basis) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-weekly-count?day=${day}&basis=${basis}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverGetServerMetric(
  metricName,
  currentTime,
  timelineSec,
  timePeriodSec
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/server/get-cluster-metric?` +
    `clusterName=${ECSClusterName}&serviceName=${ECSServiceName}&metricName=${metricName}` +
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}&timePeriodSec=${timePeriodSec}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverGetDBMetric(
  metricName,
  currentTime,
  timelineSec,
  timePeriodSec,
  unit,
  stat
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/server/get-db-metric?` +
    `clusterName=${DBClusterName}&metricName=${metricName}` +
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}&timePeriodSec=${timePeriodSec}&unit=${unit}&stat=${stat}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function serverGetLBMetric(
  metricName,
  currentTime,
  timelineSec,
  timePeriodSec
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/server/get-lb-metric?` +
    `availabilityZone=ap-northeast-2b&loadBalancer=${LoadBalancerName}&metricName=${metricName}` +
    `&endtimeSec=${currentTime}&timelineSec=${timelineSec}&timePeriodSec=${timePeriodSec}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsGetPersonalization() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/personal-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsGetWeeklyReservation() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/weekly-resv-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsGetSleepCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/sleep-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
export async function statisticsGetTemperatureCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/temp-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWMGetReservOnUser() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/wm-on-resv-cnt`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWMGetReservOffUser() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/wm-off-resv-cnt`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWMGetSleepModeUser() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/wm-sleep-cnt`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function statisticsWMTemperaturelevel() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/statistics/wm-temp-level`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function deviceSearch(
  devId,
  userId,
  modelCode,
  connection,
  regionMain,
  regionSub1,
  regionSub2
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/search` +
    `?deviceid=${devId}&userid=${userId}&modelcode=${modelCode}&connection=${connection}` +
    `&region-main=${regionMain}&region-sub1=${regionSub1}&region-sub2=${regionSub2}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('response: ' + JSON.stringify(response.data));
  return response.data;
}

export async function deviceDetailSearch(devId) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-detail-info?deviceid=${devId}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function deviceTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-total-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dormancyGetDormancyHistory(condition, userid) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/user/get-dormancy?` +
    `condition=${condition}&userid=${userid}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function dormancyGetDormancySearch(
  naviwatchemail,
  ip,
  userid,
  from,
  to
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/user/get-dormancy-user?` +
    `naviwatchemail=${naviwatchemail}&ip=${ip}&userid=${userid}&` +
    `from=${from}&to=${to}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function dormancyGetDormancyCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-dormancy-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('dormancy count res: ' + JSON.stringify(response));
  return response.data;
}

export async function pushSendPush(modelCode, title, content) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/server/send-push?` +
    `modelCode=${modelCode}&title=${title}&content=${content}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function recordGetRecords(searchId, idType, detail, from, to) {
  var response = undefined;

  let reqUrl =
    `/naviwatch/api/v1/monitoring/user/get-records?` +
    `idType=${idType}&searchId=${searchId}&detail=${detail}&from=${from}&to=${to}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function recordGetFirmwareRecords(searchId, idType, from, to) {
  var response = undefined;

  let reqUrl =
    `/naviwatch/api/v1/monitoring/firmware/get-records?` +
    `idType=${idType}&searchId=${searchId}&from=${from}&to=${to}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userSearch(naviwatchid, ip, userid, useremail, username) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/user/search?` +
    `naviwatchid=${naviwatchid}&ip=${ip}&userid=${userid}&` +
    `useremail=${useremail}&username=${username}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userGetTotalCount() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/user/get-total-count`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function userGetUserDetail(naviwatchId, ip, choicedUser) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/user/get-detail-info?` +
    `naviwatchid=${naviwatchId}&ip=${ip}&userid=${choicedUser}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('Response: ' + JSON.stringify(response));
  return response.data;
}

export async function userSendPush(userId, title, content) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/user/send-push?` +
    `userid=${userId}&title=${title}&content=${content}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response;
}

export async function userResetConfirmCode(userId, callback) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/user/reset-confirm-code?` +
    `userid=${userId}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  callback();
  return response;
}

export async function firmwareModelList() {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-model-list`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('modelList : ' + JSON.stringify(response));

  return response.data;
}

export async function firmwareFwlList(modelCode) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/get-fw-list?modelCode=${modelCode}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('fwList : ' + JSON.stringify(response));
  return response.data;
}
// AWSMATEIOTPF-3218 파라미터 object 처리
export async function firmwareAdd(
  naviwatchUser,
  ip,
  fwId,
  desc,
  users,
  fwInfo,
  s3Path,
  activeFlag,
  forcedFlag,
  groupOtaFlag,
  size,
  image
) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/add-firmware`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    fwId: fwId,
    description: desc,
    users: users,
    deviceFwPath: OTA_DEVICE_FW_PATH,
    deviceCertPath: OTA_DEVICE_CERT_PATH,
    certificateArn: OTA_CERTIFICATE_ARN,
    s3Bucket: OTA_S3_BUCKET,
    s3Path: s3Path,
    s3SigningPrefix: OTA_S3_SIGNING_PREFIX,
    majorVersion: fwInfo.majorVersion,
    minorVersion: fwInfo.minorVersion,
    buildNumber: fwInfo.buildNumber,
    fwType: fwInfo.fwType,
    modelCode: fwInfo.modelCode,
    modelName: fwInfo.modelName,
    activeFlag: activeFlag,
    forcedFlag: forcedFlag,
    groupOtaFlag: groupOtaFlag,
    size: size,
    image: image
  });
  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}

export async function firmwareThingsOfJob(
  majorVersion,
  minorVersion,
  modelCode,
  buildNumber,
  fwType,
  status,
  token
) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/monitoring/firmware/get-thing-of-job?` +
    `majorVersion=${majorVersion}&minorVersion=${minorVersion}&` +
    `modelCode=${modelCode}&buildNumber=${buildNumber}` +
    `&fwType=${fwType}&status=${status}&token=${token}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('firmwareThingsOfJob response : ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareGetJobFile(jobId) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/monitoring/firmware/get-job-doc?` + `jobId=${jobId}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareSearchThingGroups(groupName, token) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/monitoring/firmware/search-thinggroups?` +
    `groupName=${groupName}&token=${token}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

export async function firmwareSearchThings(
  attributeName,
  attributeValue,
  token
) {
  var response = undefined;
  console.log('call firmwareThingsOfJob');
  let reqUrl =
    `/naviwatch/api/v1/monitoring/firmware/search-things?` +
    `attributeName=${attributeName}&attributeValue=${attributeValue}&token=${token}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);

  console.log('response: ' + JSON.stringify(response));
  return response.data;
}

// AWSMATEIOTPF-3218 파라미터 object 처리
export async function firmwareGetGroupUsers(selectedFW) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/firmware/get-group-users?` +
    `majorVersion=${selectedFW.majorVersion}&` +
    `minorVersion=${selectedFW.minorVersion}&` +
    `buildNumber=${selectedFW.buildNumber}&` +
    `modelCode=${selectedFW.modelCode}&fwType=${selectedFW.fwType}&` +
    `jobId=${selectedFW.jobId}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  console.log('response.data: ' + JSON.stringify(response.data));
  return response.data;
}

// AWSMATEIOTPF-3218 파라미터 object 처리
export async function firmwareSetGroupUsers(
  naviwatchUser,
  ip,
  fwInfo,
  userlist,
  s3Path
) {
  var response = undefined;
  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    majorVersion: fwInfo.majorVersion,
    minorVersion: fwInfo.minorVersion,
    buildNumber: fwInfo.buildNumber,
    modelCode: fwInfo.modelCode,
    modelName: fwInfo.modelName,
    fwType: fwInfo.fwType,
    userList: userlist,
    deviceFwPath: OTA_DEVICE_FW_PATH,
    deviceCertPath: OTA_DEVICE_CERT_PATH,
    certificateArn: OTA_CERTIFICATE_ARN,
    s3Bucket: OTA_S3_BUCKET,
    s3Path: s3Path,
    s3SigningPrefix: OTA_S3_SIGNING_PREFIX
  });
  console.log('Data : ' + data);
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/set-group-users`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  console.log('userlist: ' + userlist);
  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}

// AWSMATEIOTPF-3218 파라미터 object 처리
export async function firmwareModify(
  naviwatchUser,
  ip,
  fwInfo,
  activeFlag,
  forcedFlag,
  groupOtaFlag,
  groupList,
  description,
  s3Path
) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/firmware/modify-firmware`;
  let data = JSON.stringify({
    naviwatchUser: naviwatchUser,
    ip: ip,
    majorVersion: fwInfo.majorVersion,
    minorVersion: fwInfo.minorVersion,
    buildNumber: fwInfo.buildNumber,
    modelCode: fwInfo.modelCode,
    modelName: fwInfo.modelName,
    fwType: fwInfo.fwType,
    activeFlag: activeFlag,
    forcedFlag: forcedFlag,
    groupOtaFlag: groupOtaFlag,
    groupList: groupList,
    fwDescription: description,
    deviceFwPath: OTA_DEVICE_FW_PATH,
    deviceCertPath: OTA_DEVICE_CERT_PATH,
    certificateArn: OTA_CERTIFICATE_ARN,
    s3Bucket: OTA_S3_BUCKET,
    s3Path: s3Path,
    s3SigningPrefix: OTA_S3_SIGNING_PREFIX
  });
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.post(reqUrl, data, config);
  return response.data;
}

// AWSMATEIOTPF-3218 파라미터 object 처리
export async function firmwareDelete(
  naviwatchUser,
  ip,
  fwInfo,
  s3Path,
  endCallback
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/firmware/delete-firmware?` +
    `naviwatchUser=${naviwatchUser}&ip=${ip}` +
    `&majorVersion=${fwInfo.majorVersion}&minorVersion=${fwInfo.minorVersion}` +
    `&buildNumber=${fwInfo.buildNumber}&fwType=${fwInfo.fwType}` +
    `&modelCode=${fwInfo.modelCode}&s3Bucket=${OTA_S3_BUCKET}&s3Path=${s3Path}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  endCallback();
  return response.data;
}

export async function sacStart(
  deviceId,
  irHubId,
  irRemoteId,
  powerSavingMode,
  acStartTemp,
  airLowestTemp,
  acHighestTemp,
  acLowestTemp
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-start?` +
    `deviceId=${deviceId}&irHubId=${irHubId}&powerSavingMode=${powerSavingMode}&` +
    `irRemoteId=${irRemoteId}&acStartTemp=${acStartTemp}&` +
    `airLowestTemp=${airLowestTemp}&acHighestTemp=${acHighestTemp}&` +
    `acLowestTemp=${acLowestTemp}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacStop(deviceId) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-stop?` + `deviceId=${deviceId}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacGetParams(deviceId) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-get-param?` +
    `deviceId=${deviceId}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacGetEnvs(deviceId) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-get-env?` + `deviceId=${deviceId}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacGetStatus(deviceId) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-get-status?` +
    `deviceId=${deviceId}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacGetLog(deviceId, from, to, type) {
  var response = undefined;
  var _from = '';
  var _to = '';
  if (from !== '') {
    _from = Date.parse(from);
  }
  if (to !== '') {
    _to = Date.parse(to);
  }
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-log?` +
    `deviceId=${deviceId}&from=${_from}&to=${_to}&type=${type}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacGetAllCsvLog(deviceId, from, to, type) {
  var response = undefined;
  var _from = '';
  var _to = '';
  if (from !== '') {
    _from = Date.parse(from);
  }
  if (to !== '') {
    _to = Date.parse(to);
  }
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-all-csv-log?` +
    `deviceId=${deviceId}&from=${_from}&to=${_to}&type=${type}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacSetParams(
  deviceId,
  irEnable,
  irHubId,
  irRemoteId,
  powerSavingMode,
  acStartTemp,
  airLowestTemp,
  acHighestTemp,
  acLowestTemp
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-set-param?` +
    `deviceId=${deviceId}&irEnable=${irEnable}&irHubId=${irHubId}&irRemoteId=${irRemoteId}` +
    `&powerSavingMode=${powerSavingMode}&acStartTemp=${acStartTemp}&airLowestTemp=${airLowestTemp}` +
    `&acHighestTemp=${acHighestTemp}&acLowestTemp=${acLowestTemp}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacSetEnvs(
  deviceId,
  judgeValue,
  timeTempDownByAir,
  timeTempUpByAir,
  timeTempDownByAC,
  timeTempUpByAC,
  checkInterval,
  waittimeAfterACUp,
  waittimeAfterACDown,
  waittimeAfterPowerOn,
  powerSavingRunningMinutes,
  powerSavingBreakTemp,
  powerSavingRunningMaxCount,
  airTemperatureRefreshTime,
  irCmdRetryCnt,
  irSendInterval,
  acMode,
  acFanSpeed,
  coolingPossibleGap,
  targetCoolingPossibleGap,
  exitWaitPendingTime
) {
  var response = undefined;
  let reqUrl =
    `/naviwatch/api/v1/monitoring/device/sac-set-env?` +
    `deviceId=${deviceId}&judgeValue=${judgeValue}&checktimeTempDownByAir=${timeTempDownByAir}` +
    `&checktimeTempUpByAir=${timeTempUpByAir}&checktimeTempDownByAC=${timeTempDownByAC}` +
    `&checktimeTempUpByAC=${timeTempUpByAC}&checkInterval=${checkInterval}` +
    `&waitingTimeAfterACUp=${waittimeAfterACUp}&waitingTimeAfterACDown=${waittimeAfterACDown}` +
    `&waitingTimeAfterPowerOn=${waittimeAfterPowerOn}&powerSavingRunningMinutes=${powerSavingRunningMinutes}` +
    `&powerSavingBreakTemp=${powerSavingBreakTemp}&powerSavingRunningMaxCount=${powerSavingRunningMaxCount}` +
    `&airTemperatureRefreshTime=${airTemperatureRefreshTime}` +
    `&irRetryCnt=${irCmdRetryCnt}&irSendInterval=${irSendInterval}&acMode=${acMode}&acFanSpeed=${acFanSpeed}` +
    `&coolingPossibleGap=${coolingPossibleGap}&targetCoolingPossibleGap=${targetCoolingPossibleGap}` +
    `&exitWaitPendingTime=${exitWaitPendingTime}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacGetServerMem(deviceId) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/server/sac-memory?deviceId=${deviceId}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function sacGetServerState(deviceId) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/server/sac-state?deviceId=${deviceId}`;

  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function eoneGetSleepStatusOperationSetting(deviceId) {
  const url =
    'https://qdoeu9hskh.execute-api.ap-northeast-2.amazonaws.com/prod/api/v2/sleep/set/' +
    deviceId;

  const requestOptions = {
    method: 'GET',
    headers: { 'content-type': 'application/json;charset=UTF-8' }
  };

  try {
    const response = await fetch(`${url}`, requestOptions);

    if (response && response.ok) {
      const result = await response.json();
      return result;
    } else {
      return { code: response.status, msg: response.statusText, data: '' };
    }
  } catch (e) {
    console.log(`${url} error API: ${e}`);
  }

  return false;
}

export async function eoneSetSleepStatusOperationSetting(
  deviceId,
  side,
  payload
) {
  let url =
    'https://qdoeu9hskh.execute-api.ap-northeast-2.amazonaws.com/prod/api/v2/sleep/set/' +
    deviceId;
  url +=
    '?' +
    'set=' +
    payload.set +
    '&asleep=' +
    payload.asleep +
    '&rem=' +
    payload.rem +
    '&awake=' +
    payload.awake +
    '&hour=' +
    payload.hour +
    '&minute=' +
    payload.minute +
    '&standby=' +
    payload.standby +
    '&sensorId=' +
    payload.sensorId;
  url += '&side=' + side;
  const requestOptions = {
    method: 'GET',
    headers: { 'content-type': 'application/json;charset=UTF-8' }
  };

  try {
    const response = await fetch(`${url}`, requestOptions);

    if (response && response.ok) {
      const result = await response.json();
      return result;
    } else {
      return { code: response.status, msg: response.statusText, data: '' };
    }
  } catch (e) {
    console.log(`${url} error API: ${e}`);
  }

  return false;
}

export async function eoneSleepStatusReport(deviceId, from, to) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/eone-sleep-report?deviceId=${deviceId}&from=${from}&to=${to}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function getSleepSensorData(deviceId, startDate, endDate) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/get-sleep-info?deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}

export async function getEcsLogLevel() {
  const reqUrl = '/naviwatch/api/v1/monitoring/server/get-ecs-log-level';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  try {
    const response = await ApiInstance.get(reqUrl, config);

    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function setEcsLogLevel(logLevel) {
  const reqUrl = '/naviwatch/api/v1/monitoring/server/set-ecs-log-level';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  const data = JSON.stringify({
    level: logLevel
  });

  try {
    const response = await ApiInstance.post(reqUrl, data, config);
    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function getEcsBlackList() {
  const reqUrl = '/naviwatch/api/v1/monitoring/server/get-ecs-blacklist';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };
  try {
    const response = await ApiInstance.get(reqUrl, config);
    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function syncEcsBlackList() {
  const reqUrl = '/naviwatch/api/v1/monitoring/server/sync-ecs-blacklist';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  const data = JSON.stringify({});

  try {
    const response = await ApiInstance.post(reqUrl, data, config);
    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function addEcsBlackList(blacklist) {
  const reqUrl = '/naviwatch/api/v1/monitoring/server/add-ecs-blacklist';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  const data = JSON.stringify({
    blacklist
  });

  try {
    const response = await ApiInstance.post(reqUrl, data, config);
    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function deleteEcsBlackList(blacklist) {
  const reqUrl = '/naviwatch/api/v1/monitoring/server/delete-ecs-blacklist';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    },
    data: JSON.stringify({
      blacklist
    })
  };

  try {
    const response = await ApiInstance.delete(reqUrl, config);
    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function getLambdaErrorCount(unit) {
  const reqUrl = `/naviwatch/api/v1/monitoring/statistics/lambda-error?unit=${unit}`;
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  try {
    const response = await ApiInstance.get(reqUrl, config);

    if (response.status == 200) {
      return response.data.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function getNlbTcpCount() {
  const reqUrl = '/naviwatch/api/v1/monitoring/statistics/nlb-count';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  try {
    const response = await ApiInstance.get(reqUrl, config);

    if (response.status == 200) {
      return response.data.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export async function getDynamoQueryCount() {
  const reqUrl = '/naviwatch/api/v1/monitoring/statistics/dynamo-count';
  const config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    }
  };

  try {
    const response = await ApiInstance.get(reqUrl, config);

    if (response.status == 200) {
      return response.data.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }

  return null;
}

export function formatter(n) {
  if (n !== undefined) {
    var tempval = new Date((n + 60 * 60 * 9) * 1000);
    var timeStr = tempval.toISOString();
    timeStr = timeStr.replaceAll('T', ' ');
    timeStr = timeStr.slice(0, 19);
    return timeStr;
  } else {
    return undefined;
  }
}

// AWSMATEIOTPF-3218 코드 내 모델명 입력 받아 동작 처리 softcoding
const MODEL_TYPE_WM_MIN = 0x001;
const MODEL_TYPE_WM_MAX = 0x0ff;
const MODEL_TYPE_EM_MIN = 0x101;
const MODEL_TYPE_EM_MAX = 0x1ff;
const MODEL_TYPE_FM_MIN = 0x201;
const MODEL_TYPE_FM_MAX = 0x2ff;

export function getS3Path(fwInfo) {
  var s3Path = 'em/suchoi-test';
  console.log('getS3Path firmwareInfo:' + JSON.stringify(fwInfo));
  // has downloadUrl
  if ('downloadUrl' in fwInfo) {
    console.log('downloadUrl' in fwInfo);
    var arrPath = fwInfo.downloadUrl.split('/');
    s3Path = arrPath[arrPath.length - 3] + '/' + arrPath[arrPath.length - 2];
  } else {
    var intModelCode = parseInt(fwInfo.modelCode);
    var modelType = '';
    if (
      (intModelCode >= MODEL_TYPE_WM_MIN) &
      (intModelCode <= MODEL_TYPE_WM_MAX)
    ) {
      modelType = 'wm';
    } else if (
      (intModelCode >= MODEL_TYPE_EM_MIN) &
      (intModelCode <= MODEL_TYPE_EM_MAX)
    ) {
      modelType = 'em';
    } else if (
      (intModelCode >= MODEL_TYPE_FM_MIN) &
      (intModelCode <= MODEL_TYPE_FM_MAX)
    ) {
      modelType = 'fm';
    }

    if (modelType != '') {
      s3Path = modelType + '/' + fwInfo.modelName.toLowerCase();
    }
  }
  console.log('s3 path:' + s3Path);
  return s3Path;
}

export function getFWType(type) {
  switch (type) {
    case 1:
      return 'MCU';
    case 2:
      return 'Wifi';
    case 3:
      return 'Certificate';
    default:
      return 'Not Defined';
  }
}

export async function deviceGetMateReport() {
  var response = undefined;
  if (deploy === 'prod') {
    let reqUrl = `/naviwatch/api/v1/monitoring/device/get-report-url`;
    let config = {
      headers: {
        authorizer: sessionStorage.getItem('idToken')
      }
    };
    response = await ApiInstance.get(reqUrl, config);
    return response.data;
  } else {
    return { data: null };
  }
}

export async function deviceGetMateLog(values) {
  var response = undefined;
  let reqUrl = `/naviwatch/api/v1/monitoring/device/log`;
  let config = {
    headers: {
      authorizer: sessionStorage.getItem('idToken')
    },
    params: {
      ...values,
      limit: 3000
    }
  };
  response = await ApiInstance.get(reqUrl, config);
  return response.data;
}
