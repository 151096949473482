import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core';

import { userInfoContext } from '../../../../App';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION,
  WRITE_PERMISSION
} from '../../../Common/Config/naviwatchConfig';
import BlackList from '../component/BlackList';
import BlackListToolbar from '../component/BlackListToolbar';
import {
  getEcsBlackList,
  syncEcsBlackList,
  addEcsBlackList,
  deleteEcsBlackList
} from '../../APIs/mateApis';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const ConverterBlackList = ({ history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState('');
  const [permission, setPermission] = useState(NO_PERMISSION);

  const [ecsBlackList, setEcsBlackList] = useState([]);
  const [dynamoDbBlackList, setDynamoDbBlackList] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== 'undefined') {
      const pageInfoObj = JSON.parse(pageInfo);
      const pageArray = pageInfoObj.filter(page =>
        page.name.toLowerCase().includes('device')
      );
      if (pageArray !== undefined && pageArray.length > 0) {
        const page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(() => {
    getBlackList();
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  const getBlackList = async () => {
    try {
      setLoading(true);
      const response = await getEcsBlackList();
      if (response.data) {
        if (!response.data.dynamoDb) {
          response.data.dynamoDb = [];
        }
        setDynamoDbBlackList(response.data.dynamoDb.sort());
        let convertList = response.data.converter;
        convertList.map(ip => ip['getResult'].sort());
        setEcsBlackList(convertList);
      }
    } catch (e) {
      console.log('blackList error: ' + e);
    }
    setLoading(false);
  };

  const blackList = useMemo(() => {
    let result = [];
    let allList = [];
    ecsBlackList.forEach(ipList => {
      allList = allList.concat(ipList['getResult']);
    });
    allList = allList.concat(dynamoDbBlackList);

    const allValues = [...new Set(allList)].sort(); // Get all unique values sorted
    const dynamoSet = new Set(dynamoDbBlackList);
    const dynamoResult = allValues.map(value =>
      dynamoSet.has(value) ? value : '-'
    );
    dynamoResult.forEach(id => result.push({ dynamoDb: id }));
    ecsBlackList.forEach(ipList => {
      const set = new Set(ipList['getResult']);
      const idList = allValues.map(value => (set.has(value) ? value : '-'));
      idList.forEach((id, index) => {
        if (result[index]) {
          result[index][ipList['ip']] = id;
        } else {
          let ip = ipList['ip'];
          result.push({ [ip]: id });
        }
      });
    });
    return result;
  }, [
    ecsBlackList,
    dynamoDbBlackList,
    ecsBlackList.length,
    dynamoDbBlackList.length
  ]);

  const deleteBlackList = async id => {
    try {
      setLoading(true);
      const response = await deleteEcsBlackList(id);
      if (response && response.code == 200) {
        setEcsBlackList([]);
        setDynamoDbBlackList([]);
        getBlackList();
        setLoading(false);
      }
    } catch (e) {
      console.log('deleteBlackList error: ' + e);
    }
  };

  const syncBlackList = async () => {
    try {
      setLoading(true);
      setEcsBlackList([]);
      setDynamoDbBlackList([]);
      const response = await syncEcsBlackList();
      if (response && response.code == 200) {
        getBlackList();
        setLoading(false);
      }
    } catch (e) {
      console.log('syncBlackList error: ' + e);
    }
  };

  const addBlackList = async idList => {
    try {
      console.log(idList);
      if (idList.length === 0) {
        return;
      }
      setLoading(true);
      const response = await addEcsBlackList(idList);
      if (response && response.code == 200) {
        setEcsBlackList([]);
        setDynamoDbBlackList([]);
        getBlackList();
        handleAddClose();
        setLoading(false);
      }
    } catch (e) {
      console.log('deleteBlackList error: ' + e);
    }
  };

  const handleAddOpen = () => {
    setOpen(true);
  };
  const handleAddClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {permission >= READ_PERMISSION ? (
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <BlackListToolbar
            syncBlackList={syncBlackList}
            addBlackList={addBlackList}
            open={open}
            handleAddOpen={handleAddOpen}
            handleAddClose={handleAddClose}
          />
          {blackList && blackList.length > 0 && (
            <div className={classes.content}>
              <BlackList
                blackList={blackList}
                deleteBlackList={deleteBlackList}
              />
            </div>
          )}
        </div>
      ) : (
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      )}
    </div>
  );
};

export default ConverterBlackList;
