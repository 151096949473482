import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  CardActions,
  Card,
  Table,
  TableBody,
  Button,
  Checkbox
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteIcon from '@material-ui/icons/Delete';

import BlackListDeletePopup from './BlackListDeletePopup';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '50px'
  }
}));

const BlackList = ({ blackList, deleteBlackList }) => {
  const [open, setOpen] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const classes = useStyles();

  const handleDelete = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    if (event.target.checked) {
      setCheckedList([...checkedList, event.target.name]);
    } else {
      setCheckedList(checkedList.filter(id => id !== event.target.name));
    }
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(blackList[0]).map((ip, index) => (
                    <TableCell key={ip + index} align={'center'}>
                      <b>{ip}</b>
                    </TableCell>
                  ))}
                  <TableCell key={'checked'} align={'center'} width={100}>
                    <b>삭제여부</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blackList.map((idList, index) => {
                  let currentId = '';
                  return (
                    <TableRow className={classes.tableRow} hover key={index}>
                      {Object.keys(idList).map((ip, index) => {
                        if (idList[ip] !== '-') {
                          currentId = idList[ip];
                        }
                        return (
                          <TableCell align={'center'}>{idList[ip]}</TableCell>
                        );
                      })}
                      <TableCell align={'center'}>
                        <Checkbox
                          checked={!!checkedList.find(id => id === currentId)}
                          onChange={handleChange}
                          name={currentId}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          disabled={!checkedList.length}
          onClick={() => handleDelete(checkedList)}
          variant="contained"
          color="primary">
          삭제
        </Button>
      </CardActions>
      <BlackListDeletePopup
        open={open}
        handleClose={handleClose}
        deleteBlackList={deleteBlackList}
        deleteIdList={checkedList}
      />
    </Card>
  );
};

export default BlackList;
