import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import CellWifiTwoToneIcon from '@material-ui/icons/CellWifiTwoTone';
import PortableWifiOffTwoToneIcon from '@material-ui/icons/PortableWifiOffTwoTone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { deviceDetailInfo } from '../../../APIs/tokapis';
import DetailDialog from './DetailDialog';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';
import useAsync from '../../../../../asyncNet';
import axios from 'axios';

import {
  STRING_DEVICE_TYPE,
  STRING_SHOW_DETAIL,
  STRING_UNKNOWN,
  STRING_DEVICE_ID,
  STRING_CONNECTION_STATE,
  STRING_IDENTIFIER
} from '../../../StringTable/StringTable';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));


const DeviceTable = props => {
  const { className, devices, ip, ...rest } = props;
  const classes = useStyles();
  const [selectedDevices, setselectedDevices] = useState([]);
  const [deviceId, setDeviceChoice] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [state, tryRefetch] = useAsync(() => deviceDetailInfo(deviceId.slice(0, 12), deviceId.slice(12, 16), ip), [], true);
  const { loading, data: deviceInfo, error } = state;


  useEffect(() => {
    if (deviceId !== '') {
      tryRefetch();
    }

  }, [deviceId]);


  const handleClickOpen = (deviceId) => {
    console.log('handleClickOpen');
    setDeviceChoice(deviceId);
    setOpen(true);
  };

  const handleClose = () => {
    setDeviceChoice('');
    setOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };


  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_DEVICE_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_IDENTIFIER}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_DEVICE_TYPE}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_CONNECTION_STATE}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_SHOW_DETAIL}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices !== null && devices !== undefined && devices.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(device => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={device.deviceId}
                      selected={selectedDevices.indexOf(device.deviceId) !== -1}
                    >
                      <TableCell align={'center'}>
                        {device.deviceId ? device.deviceId : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {device.userId ? device.userId : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {device.modelName ? device.modelName : '-'}
                      </TableCell>
                      <TableCell align={'center'}>
                        {device.connection == true &&
                          <CellWifiTwoToneIcon color={'secondary'} />
                        }
                        {
                          device.connection == false &&
                          <PortableWifiOffTwoToneIcon color={'error'} />
                        }
                        {
                          device.connection == null &&
                          <HelpOutlineIcon color={'primary'} />
                        }
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_SHOW_DETAIL}>
                          <IconButton aria-label={STRING_SHOW_DETAIL}
                            onClick={() => handleClickOpen(device.deviceId)}>
                            <ZoomInTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          {devices !== null && devices !== undefined &&
            <TablePagination
              component="div"
              count={devices !== null && devices !== undefined && devices.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />}
        </CardActions>
      </Card>
      <DetailDialog open={open} handleClose={handleClose} deviceInfo={deviceInfo} rendering={tryRefetch} />
    </div>
  );
};

DeviceTable.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.array.isRequired
};

export default DeviceTable;
