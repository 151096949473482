import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { TableHead, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useAsync from '../../../../../asyncNet';
import Paper from '@material-ui/core/Paper';
import { getControlLog } from '../../../APIs/tokapis';
import {
  STRING_DEVICE_CONTROL_LOG_INFO,
  STRING_NO_CONTROL_LOG,
  STRING_DATE,
  STRING_SETTING_MODE,
  STRING_SETTING_VALUE,
  STRING_DATE_FORMAT_INCLUDE_SEC
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deviceView: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  deviceDescriptView: {
    display: 'flex',
    flexDirection: 'column'
  },
  deviceDescriptDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));


const DeviceControlLog = props => {
  const { className, open, handleClose, deviceInfo, startTime, endTime, ...rest } = props;
  const classes = useStyles();
  const [state, tryRefetch] = useAsync(() => getControlLog(deviceInfo, startTime, endTime), [], true);
  const { loading, data: controlLog, error } = state;

  useEffect(() => {
    if (deviceInfo !== null && startTime !== null && endTime !== null) {
      tryRefetch();
    }
  }, [deviceInfo, startTime, endTime]);



  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"

      >
        <DialogTitle id="max-width-dialog-title">Device Control Log</DialogTitle>
        <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_DEVICE_CONTROL_LOG_INFO}</span></Alert>
        <Divider className={classes.divider} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {
                controlLog && controlLog.data.log.length != 0 && (
                  <TableRow>
                    <TableCell align="center"><b>{STRING_DATE}</b></TableCell>
                    <TableCell align="center"><b>{STRING_SETTING_MODE}</b></TableCell>
                    <TableCell align="center"><b>{STRING_SETTING_VALUE}</b></TableCell>
                  </TableRow>
                )}
              {
                controlLog && controlLog.data.log.length == 0 && (
                  <TableRow>
                    <TableCell align="center"><b>{STRING_NO_CONTROL_LOG}</b></TableCell>
                  </TableRow>
                )
              }
            </TableHead>
            <TableBody>
              {
                controlLog && controlLog.data.log.length != 0 &&
                controlLog.data.log.map((element) => (
                  <TableRow key={element.registerAt}>
                    <TableCell align="center">{moment(element.registerAt).local().format(STRING_DATE_FORMAT_INCLUDE_SEC)}</TableCell>
                    <TableCell align="center">{element.mode}</TableCell>
                    <TableCell align="center">{element.param.toString()}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeviceControlLog.propTypes = {
  className: PropTypes.string
};

export default DeviceControlLog;
