import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const BlackListDeletePopup = ({
  open,
  handleClose,
  deleteBlackList,
  deleteIdList
}) => {
  const classes = useStyles();
  const handleDelete = () => {
    deleteBlackList(deleteIdList);
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Alert severity="warning">
            <span>{`${deleteIdList} 을/를 삭제하시겠습니까?`}</span>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary" autoFocus>
            삭제
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BlackListDeletePopup;
